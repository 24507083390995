/* istanbul ignore file */
import appInfo from '../../package.json';

import { LoadOptions, Region } from '@fingerprintjs/fingerprintjs-pro';

export const AIRCALL_API_URL = process.env.REACT_APP_API_URL!;
export const AIRCALL_AUTHENTICATION_API_URL = process.env.REACT_APP_AUTH_API_URL!;

export const AIRCALL_MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
export const AIRCALL_USER_MANAGEMENT_URL = process.env.REACT_APP_USER_MANAGEMENT_URL!;

export const AIRCALL_DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL!;
export const AIRCALL_BRIDGE_API_URL = process.env.REACT_APP_BRIDGE_API_URL!;

export const INTEGRATIONS_MANAGER_URL = process.env.REACT_APP_INTEGRATIONS_MANAGER_URL!;
export const BULK_USERS_UPLOAD_URL = process.env.REACT_APP_BULK_USERS_UPLOAD_URL!;

export const AIRCALL_API_VERSIONS = {
  v1: 'v1/',
  v3: 'v3/',
  v4: 'v4/',
  merlin: 'merlin/',
};

export const AIRCALL_ADVANCED_ANALYTICS = 'analytics/';

export const AIRCALL_CALLS_ASSET = 'private/calls/';

export const AIRCALL_GRAPHQL_API = process.env.REACT_APP_GRAPHQL_API_URL!;

export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const IS_NETLIFY_DOMAIN =
  process.env.REACT_APP_DOMAIN_NAME === 'netlify' ||
  process.env.REACT_APP_DOMAIN_NAME === 'aircall-netlify.com';
export const IS_TEST = process.env.NODE_ENV === 'test';

export const DOMAIN_NAME = process.env.REACT_APP_DOMAIN_NAME!;

export const DATADOG_APPLICATION_ID = process.env.REACT_APP_DATADOG_APPLICATION_ID!;
export const DATADOG_APP_HOSTNAME = process.env.DATADOG_APP_HOSTNAME!;
export const PUSHER_API_KEY = process.env.REACT_APP_PUSHER_API_KEY!;

export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID!;
export const GTM_APP_ID = process.env.REACT_APP_GTM_APP_ID!;
export const PIPEDRIVE_CLIENT_ID = process.env.REACT_APP_PIPEDRIVE_CLIENT_ID!;

export const DATADOG_TOKEN = process.env.REACT_APP_DATADOG_TOKEN!;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID!;

export const COMMIT_SHA = process.env.REACT_APP_COMMIT_SHA!;

export const SANDBOX_DOMAIN_WHITELIST =
  process.env.REACT_APP_SANDBOX_DOMAIN_WHITELIST?.split(',') || [];

export const IRONCLAD_ACCESS_ID = process.env.REACT_APP_IRONCLAD_ACCESS_ID!;
export const IRONCLAD_TOU_GROUP_KEY = process.env.REACT_APP_IRONCLAD_TOU_GROUP_KEY!;
export const IRONCLAD_TC_GROUP_KEY = process.env.REACT_APP_IRONCLAD_TC_GROUP_KEY!;

export const APP_CONFIG = {
  release: appInfo.version,
  /** The device name for tracking */
  device: 'dashboard',
  environment: process.env.REACT_APP_BUILD_ENV!,
};

export const FINGERPRINTJS_CONFIG: LoadOptions = {
  apiKey: process.env.REACT_APP_FINGERPRINTJS_KEY as string,
  region: process.env.REACT_APP_FINGERPRINTJS_REGION as Region,
  endpoint: process.env.REACT_APP_FINGERPRINTJS_ENDPOINT as string,
};

export const SAML_ENTITY_ID = process.env.REACT_APP_SAML_ENTITY_ID!;
export const SAML_CONSUMER_URL = process.env.REACT_APP_SAML_CONSUMER_URL!;
export const OLD_COOKIE_DOMAIN = process.env.REACT_APP_OLD_COOKIE_DOMAIN;

export const GAINSIGHT_SCRIPT_SDK_LINK = process.env.REACT_APP_GAINSIGHT_SCRIPT_SDK_LINK!;
export const GAINSIGHT_TAG_KEY = process.env.REACT_APP_GAINSIGHT_TAG_KEY!;

export const META_APP_ID = process.env.REACT_APP_META_APP_ID!;
export const META_CONFIG_ID = process.env.REACT_APP_META_CONFIG_ID!;
export const FACEBOOK_SDK_URL = process.env.REACT_APP_FACEBOOK_SDK_URL!;
export const META_GRAPH_API_VERSION = process.env.REACT_APP_META_GRAPH_API_VERSION!;
export const META_SOLUTION_ID = process.env.REACT_APP_META_SOLUTION_ID!;

export const TANDEM_APP_ID = process.env.REACT_APP_TANDEM_APP_ID!;
export const TANDEM_ENVIRONMENT = process.env.REACT_APP_TANDEM_ENVIRONMENT!;
