export const AGENT_SETUP_GUIDE_URL = 'https://aircall.io/agent/';
export const AGENT_SETUP_GUIDE_URL_FOR_PHONE = 'https://aircall.io/agent-phone/';
export const DOWNLOAD_APP_ROUTE_PHONE = 'https://aircall.io/download-phone';

export const DEVELOPER_DOC_URL = 'https://developer.aircall.io/';

export const BILLING_URL = 'https://billing.aircall.io/';

export const MORE_INTEGRATIONS_URL =
  'https://aircall.io/call-center-software-integrations/?distributor=aircall';

export const USER_PICTURE_FILE_NAME_TEMPLATE =
  'companies/:companyId/users/:userId/photo/:time/:fileId';

export const COMPANY_LOGO_FILE_NAME_TEMPLATE = 'companies/:companyId/photo/:time/:fileId';

export const RECORD_AUDIO_FILE_KNOWLEDGE_BASE_URL =
  'https://help.aircall.io/en/articles/5684425-configuring-numbers-voicemail-music-and-messages';

export const NUMBER_VERIFYING_HELP_URL =
  'https://help.aircall.io/en/articles/5643809-verifying-your-aircall-number-through-the-dashboard';

export const CONTACT_US_KNOWLEDGE_BASE_URL = 'https://help.aircall.io/';
export const CONTACT_US_TRAINING_VIDEOS_URL = 'https://aircall.io/on-demand/';
export const CONTACT_US_URL = 'https://aircall.io/contact';

export const SUPPORT_URL =
  'https://dashboard.aircall.io/sso/zendesk?return_to=https://support.aircall.io/hc/';

export const SIGNUP_LINK_URL = 'https://aircall.io/get-started/';
export const STAGING_SIGNUP_LINK_URL = 'https://new.aircall-staging.com/get-started/';

export const PHONE_APP_URL = 'https://phone.aircall.io/';
export const WORKSPACE_URL = 'https://workspace.aircall.io/';

export const WHATSAPP_INTEGRATION_DETAILS_URL = 'https://help.aircall.io/'; // To be replaced
