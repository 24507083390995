import { gql } from '@apollo/client';

export const GET_COMPANY_BILLING_SUMMARY_QUERY = gql`
  query GetCompanyBillingSummaryQuery {
    companyBillingSummary {
      ID
      paymentMethod
      isDelinquent
      plan
    }
  }
`;
