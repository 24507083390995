import { Banner, BannerHeading, BannerIcon, Button, Flex, Form } from '@aircall/tractor-v2';
import { LOGIN_ROUTE } from '@constants/routes.constants';
import { FormField, validateEmail } from '@dashboard/library';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace/useNavigateWithParamsReplace';
import { AnimatePresence } from 'framer-motion';
import { Form as RFForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export interface ForgottenPasswordFormValues {
  email: string;
}

export interface IForgottenPasswordFormProps {
  onSubmit: (values: ForgottenPasswordFormValues) => void;
  disabled?: boolean;
}

export function ForgottenPasswordForm({ onSubmit, disabled }: IForgottenPasswordFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigateWithParamsReplace();

  const navigateToLoginPage = () => navigate(LOGIN_ROUTE, undefined, { keepParams: true });

  return (
    <RFForm
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, hasValidationErrors, submitError }) => (
        <Form onSubmit={handleSubmit}>
          <Flex gap='s' flexDirection='column' justifyContent='center'>
            {/* Email */}
            <FormField
              name='email'
              label={t('app.form.email.label')}
              placeholder={t('forgotten_password.form.email.placeholder')}
              validate={validateEmail}
              disabled={disabled}
              getErrorMessage={t}
              data-test='email-input-forgot-password'
            />
            <AnimatePresence>
              {submitError && (
                <Banner variant='critical' key='password-reset-err' inline>
                  <BannerIcon />
                  <BannerHeading>{t(submitError)}</BannerHeading>
                </Banner>
              )}
            </AnimatePresence>

            <Flex gap='xxs' py='l'>
              <Button
                type='button'
                variant='primary'
                mode='outline'
                onClick={navigateToLoginPage}
                size='regular'
                block
              >
                {t('forgotten_password.form.cancel.label')}
              </Button>
              <Button
                type='submit'
                data-test='reset-button-forgot-password'
                disabled={pristine || hasValidationErrors || disabled}
                size='regular'
                block
              >
                {t('forgotten_password.form.submit.label')}
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    />
  );
}
