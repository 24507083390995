import { Box, getColor, getRadii, getSpace } from '@aircall/tractor-v2';
import { INLINE_END_OUTLINE_OFFSET } from '@components/Sidenav/Sidenav.utils';
import styled, { css } from '@xstyled/styled-components';

/* istanbul ignore next */
function scrollableContentBottomStyles(show: boolean) {
  return css`
    /*
     * We create a padding through a pseudo-element.
     * Using regular padding on the container would
     * cause ::before pseudo-element to be moved when
     * the bottom was reached, which is not desirable.
     */
    &::after {
      position: relative;
      flex-shrink: 0;
      block-size: ${getSpace('s')};
      content: '';
    }

    &::before {
      position: sticky;
      display: block;
      flex-shrink: 0;
      background-color: transparent;
      block-size: 1px;
      content: '';
      inline-size: 100%;
      inset-block-start: calc(100% - 1px);
      transition: background-color 0.3s ease;
    }

    ${show &&
    css`
      &::before {
        background-color: ${getColor('graphic-default')};
      }
    `}
  `;
}

export const scrollbarStyles = css`
  ::-webkit-scrollbar {
    inline-size: 16px;
  }

  ::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
    border-radius: ${getRadii('full')};
    background: #b2b2b2;
    background-clip: padding-box;
  }

  @supports not selector(::-webkit-scrollbar) {
    scrollbar-color: #b2b2b2 transparent;
  }
`;

type StyledScrollableContainerProps = {
  $hasScrollableContentBottom: boolean;
};

export const ScrollableContainer = styled(Box)<StyledScrollableContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  padding-block-start: ${getSpace('xxs')};
  padding-inline: ${INLINE_END_OUTLINE_OFFSET}px;
  padding-inline-start: 16px;
  scrollbar-gutter: stable;

  ${({ $hasScrollableContentBottom }) => scrollableContentBottomStyles($hasScrollableContentBottom)}

  ${scrollbarStyles};
`;
