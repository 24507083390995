import { useMemo } from 'react';

import {
  Banner,
  BannerHeading,
  BannerIcon,
  Box,
  Button,
  Flex,
  Form,
  Typography,
} from '@aircall/tractor-v2';
import { validateRequired, validateEmail, FormField } from '@dashboard/library';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { AUTH_STRATEGY } from '@state/app/authentication/authentication.decl';
import { FormSubscription } from 'final-form';
import { Form as RFForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

/**
 * Only subscribes to the following changes of states in react-final-form
 * to avoid unnecessary re-renderings of fields
 */
const formStateSubscription: FormSubscription = {
  dirtySinceLastSubmit: true,
  hasSubmitErrors: true,
  hasValidationErrors: true,
  pristine: true,
  submitFailed: true,
  submitSucceeded: true,
  submitting: true,
};

export interface LoginFormValues {
  email: string;
  password: string;
}

export interface LoginFormProps {
  onSubmit: (values: LoginFormValues) => void;
}

type LoginLocationState = {
  passwordResetEmailSent?: boolean;
  userCreationConfirmed?: boolean;
  search?: string;
} | null;

export function PasswordLoginForm({ onSubmit }: LoginFormProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const locationState = location.state as LoginLocationState;

  const {
    authState: { signingIn, signedInError, signedInStrategy },
  } = useAuthenticationState();

  const isAuthStrategyPassword = signedInStrategy === AUTH_STRATEGY.PASSWORD;

  const errorMsg = useMemo(() => {
    if (signedInError) {
      return t(signedInError);
    }

    return undefined;
  }, [signedInError, t]);

  return (
    <RFForm
      onSubmit={onSubmit}
      subscription={formStateSubscription}
      render={({
        handleSubmit,
        dirtySinceLastSubmit,
        submitting,
        submitFailed,
        submitSucceeded,
      }) => {
        const shouldDisableSubmit = !!(
          signingIn ||
          (signedInError && !dirtySinceLastSubmit && isAuthStrategyPassword)
        );
        const hasBeenSubmitted = submitting || submitFailed || submitSucceeded;

        return (
          <Flex flexDirection='column' gap='s'>
            {errorMsg && (
              <Banner variant='critical' inline>
                <BannerIcon />
                <BannerHeading>{errorMsg}</BannerHeading>
              </Banner>
            )}
            {/* Success message after forgotten password email was sent to user */}
            {locationState?.passwordResetEmailSent && !hasBeenSubmitted && (
              <Banner variant='success' data-test='snackbar-password-recovery-email' inline>
                <BannerIcon />
                <BannerHeading>{t('login.snack_bar.password_recovery_email_sent')}</BannerHeading>
              </Banner>
            )}
            {/* Info message when the user is finalizing the add payment card critical action */}
            {locationState?.search?.includes('token') &&
              locationState?.search?.includes('cardId') &&
              !hasBeenSubmitted && (
                <Banner variant='info' data-test='snackbar-pending-add-payment-card' inline>
                  <BannerIcon />
                  <BannerHeading whiteSpace='pre-line'>
                    {t('login.snack_bar.pending_add_payment_card')}
                  </BannerHeading>
                </Banner>
              )}
            {/* Success message after user set up password from ConfirmUserCreationPage */}
            {locationState?.userCreationConfirmed && !hasBeenSubmitted && (
              <Banner variant='success' data-test='snackbar-user-creation-confirmed' inline>
                <BannerIcon />
                <BannerHeading>{t('confirm_invitation.success.create')}</BannerHeading>
              </Banner>
            )}

            <Form onSubmit={handleSubmit}>
              <Flex flexDirection='column' gap='s'>
                {/* Email */}
                <FormField
                  name='email'
                  label={t('login.form.email.label')}
                  placeholder={t('login.form.email.placeholder')}
                  validate={validateEmail}
                  getErrorMessage={t}
                  data-test='signin-email-input'
                  size='regular'
                />

                {/* Password */}
                <FormField
                  name='password'
                  label={t('login.form.password.label')}
                  placeholder={t('login.form.password.placeholder')}
                  type='password'
                  getErrorMessage={t}
                  validate={validateRequired}
                  data-test='signin-password-input'
                  size='regular'
                />

                {/* Forget password */}
                <Box alignSelf='flex-start' display='inline-block'>
                  <Link
                    to='/forgot_password'
                    data-test='forgot-password'
                    data-tracking='password_forgotten_clicked'
                  >
                    <Typography
                      alignSelf='start'
                      color='text-interactive-primary'
                      variant='bodyMediumS'
                      p='xxs'
                    >
                      {t('login.link.forgot_password')}
                    </Typography>
                  </Link>
                </Box>

                <Button
                  data-test='signin-button'
                  disabled={shouldDisableSubmit}
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  {...(!shouldDisableSubmit && { 'data-tracking': 'user-logged-in' })}
                  size='regular'
                  block
                  type='submit'
                >
                  {t('login.form.submit.label')}
                </Button>
              </Flex>
            </Form>
          </Flex>
        );
      }}
    />
  );
}
