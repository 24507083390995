import { PropsWithChildren } from 'react';

import { ScrollableContainer } from './SidenavBody.styles';

import { Flex } from '@aircall/tractor-v2';
import { useHasScrollableContent } from '@hooks/useHasScrollableContent/useHasScrollableContent';

export function SidenavBody({ children }: Readonly<PropsWithChildren<unknown>>) {
  const { hasScrollableContentBottom, onScrollHandler, scrollableContainerRef } =
    useHasScrollableContent<HTMLDivElement>();

  return (
    <ScrollableContainer
      ref={scrollableContainerRef}
      onScroll={onScrollHandler}
      $hasScrollableContentBottom={hasScrollableContentBottom}
    >
      <Flex
        flex={1}
        as='ul'
        margin='0'
        padding='0'
        gap='xxs'
        flexDirection='column'
        alignItems='center'
      >
        {children}
      </Flex>
    </ScrollableContainer>
  );
}
