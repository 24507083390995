import { gql } from '@apollo/client';

export const AUTHORIZE_APPLICATION_MUTATION = gql`
  mutation AuthorizeApplicationMutation($input: AuthorizeApplicationInput!) {
    authorizeApplication(input: $input) {
      status
      redirectUri
    }
  }
`;
