import { useCallback, useEffect, useState } from 'react';

import { TermsOfUseModal } from '@components/TermsOfUse/TermsOfUseModal';
import { logger } from '@config/logger.config';
import { Loading } from '@dashboard/library';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useIroncladContracts } from '@hooks/useIroncladContracts';

export function TermsOfUseConditionalAccess({ children }: Readonly<{ children: JSX.Element }>) {
  const { showOnboardingTrial, enableStarterPlan, dashboardStarterEnforceTos } = useFeaturesFlags();

  const {
    data: { contractIds, countryCode },
    updateCompanySignerData,
    checkIfCompanyHasSignedLatestContracts,
    acceptContractTerms,
  } = useIroncladContracts();
  const {
    authState: { isConnectedAs },
  } = useAuthenticationState();

  const {
    currentCompany: { id: companyId },
  } = useGlobalData();
  const [displayModal, setDisplayModal] = useState<boolean>();

  const shouldDisplayLatestTerms = useCallback(async () => {
    if ((!contractIds || showOnboardingTrial || enableStarterPlan) && !dashboardStarterEnforceTos) {
      return false;
    }
    try {
      await updateCompanySignerData();
      const hasSignedLatestTerms = await checkIfCompanyHasSignedLatestContracts();
      return !isConnectedAs && !hasSignedLatestTerms;
    } catch (error) {
      const originalError = (error as Error).message;
      logger.error(`Unhandled exception while accepting T&C, ${originalError}`);
      return false;
    }
  }, [
    contractIds,
    showOnboardingTrial,
    enableStarterPlan,
    dashboardStarterEnforceTos,
    updateCompanySignerData,
    checkIfCompanyHasSignedLatestContracts,
    isConnectedAs,
  ]);

  useEffect(() => {
    const setModal = async () => {
      const modalEnabled = await shouldDisplayLatestTerms();
      setDisplayModal(modalEnabled);
    };
    setModal();
  }, [contractIds, shouldDisplayLatestTerms]);

  // We don't want to display the page before promise resolves
  if (displayModal === undefined) {
    return <Loading data-test='terms-of-use-conditional-loading' />;
  }

  return displayModal ? (
    <TermsOfUseModal
      companyId={companyId}
      countryCode={countryCode}
      acceptContractTerms={acceptContractTerms}
    />
  ) : (
    children
  );
}
