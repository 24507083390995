import { ACCEPTANCE_SOURCE } from '@constants/contract.constants';

/* eslint-disable @typescript-eslint/naming-convention */
export const AVAILABLE_COUNTRY_CODES = ['US', 'CA', 'FR', 'ES', 'AU', 'NZ'];

export const GLOBAL_COUNTRY_CODE = 'GL';

export type SignupCountry = string | null | undefined;

export interface ClickWrapProps {
  companyId: number;
  signupCountry: SignupCountry;
  groupKeys: string[];
  forceScroll?: boolean;
  disableSending?: boolean;
  allowDisagreed?: boolean;
  acceptanceLanguage?: string;
  displayAll?: boolean;
  displayImmediately?: boolean;
  dynamic?: boolean;
  renderData?: unknown;
  clickWrapStyle?: 'full' | 'scroll' | 'checkbox' | 'combined' | 'embedded';
  filter?: string;
  testMode?: boolean;
  onAll?: (eventType: string, value: unknown, context: unknown) => void;
  onError?: (eventType: string, value: unknown, context: unknown) => void;
  onValid?: (eventType: string, value: unknown, context: unknown) => void;
  onInvalid?: (eventType: string, value: unknown, context: unknown) => void;
  onReceived?: (eventType: string, value: unknown, context: unknown) => void;
  onRendered?: (eventType: string, value: unknown, context: unknown) => void;
  onDisplayed?: (eventType: string, value: unknown, context: unknown) => void;
  onSet?: (eventType: string, value: unknown, context: unknown) => void;
  onSetSignerId?: (eventType: string, value: unknown, context: unknown) => void;
  onScrolled?: (eventType: string, value: unknown, context: unknown) => void;
  onScrolledContract?: (eventType: string, value: unknown, context: unknown) => void;
}

export interface CustomData {
  companyId: number;
  companyName: string;
  email: string;
  firstName: string;
  lastName: string;
  userId: string;
}

export interface TermsOfUseModalProps {
  companyId: number;
  acceptContractTerms: (acceptanceSource?: ACCEPTANCE_SOURCE) => Promise<void>;
  countryCode?: string;
}

export type SendAcceptanceParams = {
  companyId: number;
  versionIds: string;
  contractIds: string;
  customData: Record<string, string>;
};
