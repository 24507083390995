import i18n from '../config/i18n.config';

import { FRONTEND_VALIDATION_ERRORS_TRANSLATION_KEYS } from '@constants/errors.constants';

export const MAX_NAME_LENGTH = 60;

export const validateNumberName = (value: string): string | undefined => {
  const specialCharacterRegex = /[,%_^"]/;

  if (specialCharacterRegex.test(value)) {
    return FRONTEND_VALIDATION_ERRORS_TRANSLATION_KEYS.INVALID_NUMBER_NAME;
  }

  if (!value?.length) {
    return i18n.t(FRONTEND_VALIDATION_ERRORS_TRANSLATION_KEYS.REQUIRED_FIELD);
  }

  if (value?.length > MAX_NAME_LENGTH) {
    return i18n.t(FRONTEND_VALIDATION_ERRORS_TRANSLATION_KEYS.NUMBER_NAME_LENGTH, {
      charactersLimit: MAX_NAME_LENGTH,
    });
  }

  return undefined;
};
