import { gql } from '@apollo/client';

export const GET_AUTHORIZED_APPLICATION_QUERY = gql`
  query GetAuthorizedApplicationQuery(
    $clientId: String!
    $redirectUri: String!
    $responseType: String!
    $scope: String!
    $state: String
  ) {
    getAuthorizedApplication(
      clientId: $clientId
      redirectUri: $redirectUri
      responseType: $responseType
      scope: $scope
      state: $state
      v2: true
    ) {
      clientId
      logo
      name
      redirectUri
      responseType
      scope
      source
      state
    }
  }
`;
