/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AgentRole {
  admin = "admin",
  agent = "agent",
}

export enum AgentState {
  ALWAYS_CLOSED = "ALWAYS_CLOSED",
  ALWAYS_OPENED = "ALWAYS_OPENED",
  AUTO = "AUTO",
}

export enum AnalyticsDashboardType {
  ANALYTICS = "ANALYTICS",
  AVAILABILITY = "AVAILABILITY",
  CALL_LOG = "CALL_LOG",
  CALL_QUALITY = "CALL_QUALITY",
  CALL_QUALITY_PRO = "CALL_QUALITY_PRO",
  INBOUND = "INBOUND",
  INBOUND_ACTIVITY = "INBOUND_ACTIVITY",
  INBOUND_ACTIVITY_PRO = "INBOUND_ACTIVITY_PRO",
  MONITORING = "MONITORING",
  OUTBOUND_ACTIVITY = "OUTBOUND_ACTIVITY",
  OUTBOUND_ACTIVITY_PRO = "OUTBOUND_ACTIVITY_PRO",
  OVERVIEW = "OVERVIEW",
  UNANSWERED_INBOUND_CALL = "UNANSWERED_INBOUND_CALL",
  UNANSWERED_INBOUND_CALL_PRO = "UNANSWERED_INBOUND_CALL_PRO",
  USERS_ACTIVITY = "USERS_ACTIVITY",
  USERS_ACTIVITY_PRO = "USERS_ACTIVITY_PRO",
}

export enum Availability {
  ALWAYS_CLOSED = "ALWAYS_CLOSED",
  ALWAYS_OPENED = "ALWAYS_OPENED",
  CUSTOM = "CUSTOM",
}

export enum CompanyDetailsBillingPlanTypes {
  custom_enterprise = "custom_enterprise",
  custom_partner_essentials = "custom_partner_essentials",
  custom_partner_premium = "custom_partner_premium",
  custom_partner_professional = "custom_partner_professional",
  custom_premium = "custom_premium",
  enterprise_august_2016 = "enterprise_august_2016",
  enterprise_custom_nov_2017 = "enterprise_custom_nov_2017",
  enterprise_june_2015 = "enterprise_june_2015",
  enterprise_march_2017 = "enterprise_march_2017",
  enterprise_sept_2015 = "enterprise_sept_2015",
  essentials_custom_august_2018 = "essentials_custom_august_2018",
  essentials_july_2018 = "essentials_july_2018",
  essentials_july_2020 = "essentials_july_2020",
  friend = "friend",
  partner = "partner",
  plan = "plan",
  premium_august_2016 = "premium_august_2016",
  premium_custom_nov_2017 = "premium_custom_nov_2017",
  premium_june_2015 = "premium_june_2015",
  premium_march_2017 = "premium_march_2017",
  premium_sept_2015 = "premium_sept_2015",
  pro = "pro",
  pro_oct_2014 = "pro_oct_2014",
  professional_custom_august_2018 = "professional_custom_august_2018",
  professional_july_2018 = "professional_july_2018",
  professional_july_2020 = "professional_july_2020",
  starter = "starter",
  starter_august_2016 = "starter_august_2016",
  starter_june_2015 = "starter_june_2015",
  starter_sept_2015 = "starter_sept_2015",
  trial = "trial",
  trial_partner = "trial_partner",
  trial_transition = "trial_transition",
}

export enum DayOfWeek {
  FRIDAY = "FRIDAY",
  MONDAY = "MONDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
  THURSDAY = "THURSDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
}

export enum LineState {
  ALWAYS_CLOSED = "ALWAYS_CLOSED",
  ALWAYS_OPENED = "ALWAYS_OPENED",
  AUTO = "AUTO",
}

export enum LineStatus {
  ACTION_NEEDED = "ACTION_NEEDED",
  ALWAYS_CLOSED = "ALWAYS_CLOSED",
  ALWAYS_OPENED = "ALWAYS_OPENED",
  CLOSED = "CLOSED",
  OPENED = "OPENED",
}

export enum LineVerificationState {
  MISSING_INFORMATION = "MISSING_INFORMATION",
  NOTREQUIRED = "NOTREQUIRED",
  NOT_REQUIRED = "NOT_REQUIRED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  VERIFIED = "VERIFIED",
}

export enum MetadataTypeBoolean {
  SHOWN_CONFETTI_ON_DASHBOARD = "SHOWN_CONFETTI_ON_DASHBOARD",
  SHOWN_CONFETTI_ON_PHONE = "SHOWN_CONFETTI_ON_PHONE",
}

export enum MultiMatchQueryType {
  best_fields = "best_fields",
  bool_prefix = "bool_prefix",
  cross_fields = "cross_fields",
  most_fields = "most_fields",
  phrase = "phrase",
  phrase_prefix = "phrase_prefix",
}

export enum NumberRegistrationStatus {
  approved = "approved",
  awaiting_approval = "awaiting_approval",
  not_registered = "not_registered",
  rejected = "rejected",
}

export enum SearchableAgentsSortableFields {
  ID = "ID",
  availability = "availability",
  createdAt = "createdAt",
  email = "email",
  extension = "extension",
  firstName = "firstName",
  isActive = "isActive",
  isAvailable = "isAvailable",
  lastName = "lastName",
  pictureURL = "pictureURL",
  role = "role",
  state = "state",
  timeZone = "timeZone",
}

export enum SearchableLinesSortableFields {
  ID = "ID",
  activationState = "activationState",
  agentsCounter = "agentsCounter",
  capabilities = "capabilities",
  countryISOCode = "countryISOCode",
  createdAt = "createdAt",
  flowEditorEnabled = "flowEditorEnabled",
  isIVR = "isIVR",
  isOpen = "isOpen",
  language = "language",
  name = "name",
  phoneNumber = "phoneNumber",
  provider = "provider",
  state = "state",
  status = "status",
  verificationState = "verificationState",
}

export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}

export enum SearchableTeamsSortableFields {
  ID = "ID",
  agentsCounter = "agentsCounter",
  createdAt = "createdAt",
  imageName = "imageName",
  name = "name",
  updatedAt = "updatedAt",
}

export enum StepStatus {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum StepType {
  ADD_ANOTHER_USER = "ADD_ANOTHER_USER",
  CREATE_TEAM = "CREATE_TEAM",
  LAUNCH_DASHBOARD = "LAUNCH_DASHBOARD",
  LAUNCH_PHONE = "LAUNCH_PHONE",
  MAKE_CALL = "MAKE_CALL",
  SEND_MESSAGE = "SEND_MESSAGE",
  TEST_INTEGRATION = "TEST_INTEGRATION",
}

export enum StripePlanFeatureLevel {
  custom = "custom",
  essentials = "essentials",
  friend = "friend",
  professional = "professional",
  trial = "trial",
}

export interface AddBlacklistedNumberMutationInput {
  blacklistedPhoneNumber: BlacklistedNumberInput;
}

export interface AddNumberIntegrationInput {
  integrationIds: string[];
}

export interface AddOutboundCallerMutationInput {
  data: AddOutboundCallerMutationInputData;
}

export interface AddOutboundCallerMutationInputData {
  type: string;
  relationships: AddOutboundCallerMutationInputRelationships;
}

export interface AddOutboundCallerMutationInputRelationship {
  id: string;
  type: string;
}

export interface AddOutboundCallerMutationInputRelationships {
  user: AddOutboundCallerMutationInputUser;
}

export interface AddOutboundCallerMutationInputUser {
  data: AddOutboundCallerMutationInputRelationship;
}

export interface AgentPhoneNumberInput {
  ID?: string | null;
  isEnabled?: boolean | null;
  phoneNumber: string;
}

export interface AnalyticsDashboardUrlInput {
  dashboardType: AnalyticsDashboardType;
  embedDomain: string;
  sdkVersion: string;
}

export interface AuthorizeApplicationInput {
  clientId: string;
  numberIds: string[];
  redirectUri: string;
  responseType: string;
  scope: string;
  state?: string | null;
  v2?: boolean | null;
}

export interface AuthorizeChannelsInput {
  channelPayloads: ChannelPayload[];
  cluster?: string | null;
}

export interface AvailabilitySlotInput {
  days: DayOfWeek[];
  from: number;
  to: number;
}

export interface BlacklistedNumberInput {
  phoneNumber: string;
}

export interface BusinessHoursInput {
  availability?: Availability | null;
  slots?: AvailabilitySlotInput[] | null;
  timezone?: string | null;
}

export interface ChannelPayload {
  channelName: string;
  socketId: string;
}

export interface CreateBridgeIntegrationData {
  authorId?: string | null;
  authorType?: string | null;
}

export interface CreateBridgeIntegrationInputCredentials {
  accessToken?: string | null;
  refreshToken?: string | null;
  channelId?: string | null;
  url?: string | null;
  id?: string | null;
  secret?: string | null;
  instanceUrl?: string | null;
  host?: string | null;
}

export interface CreateBridgeIntegrationMutationInput {
  credentials: CreateBridgeIntegrationInputCredentials;
  numberIds: string;
  resourceType: string;
  data?: CreateBridgeIntegrationData | null;
}

export interface CreateSlackIntegrationMutationInput {
  dashboard_url: string;
  install_params: RestSlackIntegrationInstallParams;
}

export interface CreateTagInput {
  color: string;
  name: string;
}

export interface CreateWebhookInput {
  clientId?: string | null;
  clientSecret?: string | null;
  customName?: string | null;
  events: (string | null)[];
  type: string;
  url: string;
}

export interface DeleteTagInput {
  id: string;
}

export interface EnableIntegrationMutationInput {
  solutionInstanceId: string;
}

export interface ForgotPasswordMutationInput {
  email: string;
}

export interface FunctionType {
  name?: string | null;
}

export interface GetEvaluationsFilter {
  ID?: string | null;
  callId?: string | null;
  from?: AWSTimestamp | null;
  fromCallStartedAt?: AWSTimestamp | null;
  limit?: number | null;
  maxNormalizedScore?: number | null;
  minNormalizedScore?: number | null;
  revieweeUserIds?: string[] | null;
  scoreCardId?: string | null;
  to?: AWSTimestamp | null;
  toCallStartedAt?: AWSTimestamp | null;
  token?: string | null;
}

export interface IvrSettingInput {
  ivrSetting?: IvrSettingInputFields | null;
}

export interface IvrSettingInputFields {
  key: string;
  redirectingExternalNumber: string;
  redirectingNumberId?: string | null;
}

export interface MutiMatchQueryInput {
  query: string;
  type?: MultiMatchQueryType | null;
}

export interface RemoveConnectedIntegrationInput {
  integrationIds?: (string | null)[] | null;
}

export interface ResetPasswordMutationInput {
  confirmationCode: string;
  newPassword: string;
  confirmationPassword: string;
  email: string;
}

export interface RestSlackIntegrationCallbackData {
  type: string;
  events: RestSlackIntegrationCallbackDataEvents;
}

export interface RestSlackIntegrationCallbackDataEvent {
  name: string;
  isSelected: boolean;
}

export interface RestSlackIntegrationCallbackDataEvents {
  section: string;
  events: RestSlackIntegrationCallbackDataEvent[];
}

export interface RestSlackIntegrationInstallParams {
  callback_name: string;
  callback_data: RestSlackIntegrationCallbackData;
}

export interface SearchableAgentsFilterInput {
  ID?: SearchableIntInput | null;
  and?: (SearchableAgentsFilterInput | null)[] | null;
  availability?: SearchableStringInput | null;
  createdAt?: SearchableStringInput | null;
  email?: SearchableStringInput | null;
  extension?: SearchableStringInput | null;
  firstName?: SearchableStringInput | null;
  fullName?: SearchableStringInput | null;
  isActive?: SearchableBooleanInput | null;
  isAvailable?: SearchableBooleanInput | null;
  lastName?: SearchableStringInput | null;
  not?: SearchableAgentsFilterInput | null;
  or?: (SearchableAgentsFilterInput | null)[] | null;
  pictureURL?: SearchableStringInput | null;
  role?: SearchableAgentsRoleInput | null;
  roles?: SearchableStringInput | null;
  state?: SearchableAgentsStateInput | null;
  teamsIds?: SearchableStringInput | null;
  timeZone?: SearchableStringInput | null;
}

export interface SearchableAgentsRoleInput {
  eq?: AgentRole | null;
  ne?: AgentRole | null;
}

export interface SearchableAgentsSortInput {
  direction: SearchableSortDirection;
  field: SearchableAgentsSortableFields;
}

export interface SearchableAgentsStateInput {
  eq?: AgentState | null;
  ne?: AgentState | null;
}

export interface SearchableBooleanInput {
  eq?: boolean | null;
  ne?: boolean | null;
}

export interface SearchableIntInput {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  ne?: number | null;
  range?: (number | null)[] | null;
}

export interface SearchableLinesFilterInput {
  ID?: SearchableIntInput | null;
  activationState?: SearchableStringInput | null;
  agentsCounter?: SearchableIntInput | null;
  and?: (SearchableLinesFilterInput | null)[] | null;
  capabilities?: SearchableStringInput | null;
  countryISOCode?: SearchableStringInput | null;
  createdAt?: SearchableStringInput | null;
  flowEditorEnabled?: SearchableBooleanInput | null;
  isIVR?: SearchableBooleanInput | null;
  isOpen?: SearchableBooleanInput | null;
  language?: SearchableStringInput | null;
  name?: SearchableStringInput | null;
  not?: SearchableLinesFilterInput | null;
  or?: (SearchableLinesFilterInput | null)[] | null;
  phoneNumber?: SearchableStringInput | null;
  provider?: SearchableStringInput | null;
  state?: SearchableLinesStateInput | null;
  status?: LineStatus[] | null;
  types?: SearchableStringInput | null;
  verificationState?: SearchableLinesVerificationStateInput | null;
}

export interface SearchableLinesSortInput {
  direction: SearchableSortDirection;
  field: SearchableLinesSortableFields;
}

export interface SearchableLinesStateInput {
  eq?: LineState | null;
  ne?: LineState | null;
}

export interface SearchableLinesVerificationStateInput {
  eq?: LineVerificationState | null;
  ne?: LineVerificationState | null;
}

export interface SearchableStringInput {
  eq?: string | null;
  exists?: boolean | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  match?: string | null;
  matchPhrase?: string | null;
  matchPhrasePrefix?: string | null;
  multiMatch?: MutiMatchQueryInput | null;
  ne?: string | null;
  range?: (string | null)[] | null;
  regexp?: string | null;
  terms?: (string | null)[] | null;
  wildcard?: string | null;
}

export interface SearchableTeamsFilterInput {
  ID?: SearchableIntInput | null;
  agentsCounter?: SearchableIntInput | null;
  createdAt?: SearchableStringInput | null;
  imageName?: SearchableStringInput | null;
  name?: SearchableStringInput | null;
  updatedAt?: SearchableStringInput | null;
}

export interface SearchableTeamsSortInput {
  direction: SearchableSortDirection;
  field: SearchableTeamsSortableFields;
}

export interface TextToSpeechPreviewDataAttributesInput {
  category: string;
  track?: string | null;
  language: string;
  voice: string;
  message: string;
  url: string;
  defaultUrl?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
}

export interface TextToSpeechPreviewInput {
  data?: TextToSpeechPreviewInputData | null;
}

export interface TextToSpeechPreviewInputData {
  id: string;
  type: string;
  attributes: TextToSpeechPreviewInputDataAttributes;
}

export interface TextToSpeechPreviewInputDataAttributes {
  language: string;
  voice: string;
  message: string;
}

export interface UpdateAgentInput {
  ID: string;
  businessHours?: BusinessHoursInput | null;
  currentRingtone?: string | null;
  customTwilioEdge?: string | null;
  defaultOutPhoneNumberID?: string | null;
  defaultPrefix?: string | null;
  extension?: string | null;
  firstName?: string | null;
  isOnboardedForTeamsInPhoneNumbers?: boolean | null;
  isOnboardedOnApp?: boolean | null;
  language?: string | null;
  lastName?: string | null;
  phoneNumbers?: AgentPhoneNumberInput[] | null;
  pictureURL?: string | null;
  ringTimeout?: number | null;
  role?: AgentRole | null;
  state?: AgentState | null;
  timeZone?: string | null;
  useAircallPhoneNumberAsCallerID?: boolean | null;
  wrapUpTime?: number | null;
}

export interface UpdateDispatchGroupInput {
  data?: UpdateDispatchGroupInputData | null;
}

export interface UpdateDispatchGroupInputAttributes {
  queuing_timeout?: number | null;
  priority?: number | null;
}

export interface UpdateDispatchGroupInputData {
  type?: string | null;
  id?: string | null;
  attributes?: UpdateDispatchGroupInputAttributes | null;
  relationships?: UpdateDispatchGroupInputRelationships | null;
}

export interface UpdateDispatchGroupInputRelationships {
  dispatchable?: UpdateDispatchGroupInputRelationshipsDispatchable | null;
}

export interface UpdateDispatchGroupInputRelationshipsDispatchable {
  data?: UpdateDispatchGroupInputRelationshipsDispatchableData | null;
}

export interface UpdateDispatchGroupInputRelationshipsDispatchableData {
  id?: string | null;
  type?: string | null;
}

export interface UpdateNumberInput {
  data?: UpdateNumberInputData | null;
}

export interface UpdateNumberInputData {
  id: number;
  type?: string | null;
  attributes: UpdateNumberInputDataAttributes;
}

export interface UpdateNumberInputDataAttributes {
  name?: string | null;
  number?: string | null;
  numberFormatted?: string | null;
  activationState?: string | null;
  ivr?: boolean | null;
  iso3166Alpha2?: string | null;
  state?: string | null;
  verificationState?: string | null;
  respectQueueingTime?: boolean | null;
  priority?: number | null;
  mandatoryTaggingActivated?: boolean | null;
  queueCallbackActivated?: boolean | null;
  allowInboundRecordingActions?: boolean | null;
  allowOutboundRecordingActions?: boolean | null;
  inboundRecordingActivated?: boolean | null;
  outboundRecordingActivated?: boolean | null;
  afterHoursVoicemail?: boolean | null;
  missedCallVoicemail?: boolean | null;
  welcomeMessageEnabled?: boolean | null;
  open?: boolean | null;
  replayIvrMessage?: boolean | null;
  musicOnHold?: string | null;
}

export interface UpdateTagInput {
  color: string;
  description?: string | null;
  id: string;
  name: string;
}

export interface UpdateTextToSpeechInput {
  data: UpdateTextToSpeechInputData;
}

export interface UpdateTextToSpeechInputData {
  attributes?: TextToSpeechPreviewDataAttributesInput | null;
  id: string;
  type: string;
  relationships: UpdateTextToSpeechInputRelationshipInput;
}

export interface UpdateTextToSpeechInputRelationshipInput {
  number: UpdateTextToSpeechInputRelationshipNumberInput;
}

export interface UpdateTextToSpeechInputRelationshipNumberDataInput {
  id: string;
  type: string;
}

export interface UpdateTextToSpeechInputRelationshipNumberInput {
  data: UpdateTextToSpeechInputRelationshipNumberDataInput;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
