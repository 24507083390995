import { useMemo } from 'react';

import { UseIroncladContractsReturn } from './useIroncladContracts.decl';

import { ACCEPTANCE_SOURCE } from '@constants/contract.constants';
import { IRONCLAD_TC_GROUP_KEY, IRONCLAD_TOU_GROUP_KEY } from '@constants/environment.constants';
import { ASSETS_PAGE_ROUTE } from '@constants/regex.constants';
import { useGraphQuery } from '@dashboard/library';
import {
  GetLocalisedContractsQuery,
  GetLocalisedContractsQueryVariables,
} from '@generated/GetLocalisedContractsQuery';
import { GetSignupInformation } from '@generated/GetSignupInformation';
import { GET_LOCALISED_CONTRACTS_QUERY } from '@graphql/queries/GetLocalisedContractsQuery';
import { SIGNUP_INFORMATION_QUERY } from '@graphql/queries/SignupInformationQuery';
import {
  getAvailableCountryCode,
  hasUserSignedLatestContracts,
  sendAcceptance,
  setSignerCustomData,
} from '@helpers/ironclad.helpers';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { usePathnameMatch } from '@hooks/usePathnameMatch';
/**
 * Custom hook to get a localised contracts.
 * @returns an object with the contractIds and versionIds from iron clad.
 */
export const useIroncladContracts = (): UseIroncladContractsReturn => {
  const shouldSkipQuery = usePathnameMatch([ASSETS_PAGE_ROUTE]);
  const { data: signupDetails, loading: loadingSignup } = useGraphQuery<GetSignupInformation>(
    SIGNUP_INFORMATION_QUERY,
    {
      /* We silent errors since we have a fallback if the company doesn't have any signup information */
      throwError: false,
      skip: shouldSkipQuery,
    }
  );

  const {
    currentCompany: { id: companyId, name: companyName },
    currentUser: { email, firstName, lastName, ID: userId },
  } = useGlobalData();

  const countryCode = useMemo(() => {
    if (loadingSignup) {
      return undefined;
    }

    const countryData = signupDetails?.getSignupInformation.country;

    return getAvailableCountryCode(countryData);
  }, [loadingSignup, signupDetails?.getSignupInformation.country]);

  const groupKeys = [IRONCLAD_TC_GROUP_KEY, IRONCLAD_TOU_GROUP_KEY];

  const { data, refetch, loading, error } = useGraphQuery<
    GetLocalisedContractsQuery,
    GetLocalisedContractsQueryVariables
  >(GET_LOCALISED_CONTRACTS_QUERY, {
    variables: { country: countryCode || '', groupKeys },
    skip: shouldSkipQuery || !countryCode,
  });

  const contractIds = data?.getLocalisedContracts?.map(({ ID }) => ID).join(',') || '';
  const versionIds =
    data?.getLocalisedContracts?.map(({ publishedVersion }) => publishedVersion).join(',') || '';

  const updateCompanySignerData = async () => {
    await setSignerCustomData({
      companyId,
      companyName,
      email,
      firstName,
      lastName,
      userId,
    });
  };

  const checkIfCompanyHasSignedLatestContracts = async () =>
    hasUserSignedLatestContracts(contractIds, companyId);

  const acceptContractTerms = async (
    acceptanceSource: ACCEPTANCE_SOURCE = ACCEPTANCE_SOURCE.GENERAL
  ) => {
    const customData: Record<string, string> = {
      acceptedBy: userId,
      firstName,
      lastName,
      companyName,
      email,
      acceptanceSource,
    };

    await sendAcceptance({ companyId, versionIds, contractIds, customData });
  };

  return {
    data: {
      contractIds,
      versionIds,
      countryCode,
    },
    refetch,
    loading: loadingSignup || loading,
    error,
    updateCompanySignerData,
    checkIfCompanyHasSignedLatestContracts,
    acceptContractTerms,
  };
};
