import { useEffect } from 'react';

import { APP_CONFIG } from '@constants/environment.constants';
import { LOCAL_STORAGE_KEYS } from '@constants/localStorage.constants';
import { HOME_ROUTE, PUBLIC_ROUTES } from '@constants/routes.constants';
import { checkIsUrlFromDomains } from '@helpers/url/url.helpers';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useQueryParams } from '@hooks/useQueryParams';
import { AUTHENTICATION_STATUS } from '@state/app/authentication/authentication.decl';
import { useLocation } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

interface LocationWithState extends Location {
  state: LocationState | null;
}

export interface LocationState {
  from?: string;
  search?: string;
}

export function getRedirectWhitelist(): string[] {
  return [
    'aircall.io',
    'zendesk.com',
    ...(APP_CONFIG.environment === 'staging' ? ['aircall-staging.com'] : []),
  ];
}

export function getRedirectFromState(state: LocationState | null): string | null {
  const previousPath = state?.from;
  const searchParams = state?.search;

  // if previous page is private, go to that private page
  if (previousPath && !PUBLIC_ROUTES.includes(previousPath)) {
    return searchParams ? previousPath + searchParams : previousPath;
  }

  return null;
}

export function useLoginRedirect(): void {
  const { searchParams } = useQueryParams();
  const { state } = useLocation() as unknown as LocationWithState;
  const {
    authState: { status: authStatus },
  } = useAuthenticationState();
  const navigate = useNavigateWithParamsReplace();

  const redirectFromQueryParam = searchParams.get('redirect');
  const redirectFromState = getRedirectFromState(state);
  const [savedRedirect, setSavedRedirect] = useLocalStorage<string | null>(
    LOCAL_STORAGE_KEYS.LOGIN_REDIRECT,
    null
  );
  const redirect = savedRedirect || HOME_ROUTE;

  // Save redirect in local storage for SSO flow where we leave the dashboard and come back later
  useEffect(() => {
    if (redirectFromState || redirectFromQueryParam) {
      setSavedRedirect(redirectFromState || redirectFromQueryParam);
    }
  }, [redirectFromQueryParam, redirectFromState, setSavedRedirect]);

  useEffect(() => {
    if (authStatus === AUTHENTICATION_STATUS.AUTHENTICATED) {
      // Clear saved redirect
      window.localStorage.removeItem(LOCAL_STORAGE_KEYS.LOGIN_REDIRECT);

      // if redirect is a relative path
      if (redirect.startsWith('/')) {
        navigate(redirect);
      }
      // if redirect is a url
      else if (checkIsUrlFromDomains(redirect, getRedirectWhitelist())) {
        window.location.replace(redirect);
      }
    }
  }, [authStatus, navigate, redirect, redirectFromQueryParam, savedRedirect, setSavedRedirect]);
}
