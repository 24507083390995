import { LoginLayout } from '../../components/LoginLayout/LoginLayout';

import { ForgottenPasswordForm } from './form/ForgottenPasswordForm';
import { useForgotPassword } from './hooks/useForgotPassword';

import { Box, Typography } from '@aircall/tractor-v2';
import { FormWrapper } from '@components/LoginLayout/Login.styles';
import { SignupButton } from '@pages/login/SignupButton/SignupButton';
import { useTranslation } from 'react-i18next';

export default function ForgottenPassword() {
  const { t } = useTranslation();

  const { isResetting, handleForgotPassword } = useForgotPassword();

  return (
    <LoginLayout data-test='forgotten-password-page'>
      <FormWrapper>
        <Box>
          <Box pb='s' mb='s'>
            <Typography
              variant='headingMediumL'
              color='text-base'
              as='h1'
              m='0'
              mb='s'
              textAlign='center'
            >
              {t('forgotten_password.title')}
            </Typography>

            <Typography variant='bodyRegularM' color='text-base' textAlign='center'>
              {t('forgotten_password.description')}
            </Typography>
          </Box>

          <ForgottenPasswordForm onSubmit={handleForgotPassword} disabled={isResetting} />

          <SignupButton />
        </Box>
      </FormWrapper>
    </LoginLayout>
  );
}
