import { useEffect, useState } from 'react';

import { CompanyRoutes } from './components/CompanyRoutes';
import { ProfileRoutes } from './components/ProfileRoutes';
import { SupportRoutes } from './components/SupportRoutes';
import { SidenavAccountProps } from './SidenavAccount.decl';
import { Container } from './SidenavAccount.styles';

/**
 * There is no need to test this component as it
 * is just a container.
 */
/* istanbul ignore next */
export function SidenavAccount({ expanded }: Readonly<SidenavAccountProps>) {
  const [hasCollapsedOnce, setHasCollapsedOnce] = useState(false);

  useEffect(() => {
    if (!expanded && !hasCollapsedOnce) {
      setHasCollapsedOnce(true);
    }
  }, [hasCollapsedOnce, expanded]);

  return (
    <Container expanded={expanded} shouldAnimate={hasCollapsedOnce} mt='xxs'>
      <ProfileRoutes expanded={expanded} />
      <CompanyRoutes expanded={expanded} />
      <SupportRoutes expanded={expanded} />
    </Container>
  );
}
