import { FunctionComponent, useMemo } from 'react';

import styled from 'styled-components';

import { NavItem } from '../NavItem/NavItem';

import { DEFAULT_PAGE } from './Sidebar.constants';
import { FeatureCheck, SIDEBAR_ITEMS_CONFIG, SidebarItem } from './sidebarItems';
import { sortByPosition } from './utils';

import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Box } from '@aircall/tractor-v2';
import { AnalyticsPlusAdNavItem } from '@components/AnalyticsPlusAdNavItem';
import { NavItemProps } from '@components/NavItem';
import { PERMISSIONS_ACTIONS, RESOURCE } from '@constants/permissions.constants';
import { MenuItem, useDashboardExtensionContext } from '@dashboard/extension';
import { isTruthy } from '@dashboard/library';
import { useCompanyAddons } from '@hooks/useCompanyAddons/useCompanyAddons';
import { useAdminFeatures } from '@hooks/useFeatures/useAdminFeatures';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useGetLinkAddonsConfig } from '@hooks/useGetLinkAddonsConfig/useGetLinkAddonsConfig';
import { useHasScrollableContent } from '@hooks/useHasScrollableContent/useHasScrollableContent';
import { useModuleFederationContext } from '@hooks/useModuleFederationContext/useModuleFederationContext';
import { usePermissions } from '@hooks/usePermissions';
import { useRoutePath } from '@hooks/useRoutePath/useRoutePath';
import { css } from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';

/* istanbul ignore next */
function hasScrollableContentBottomStyles(show: boolean) {
  return (
    show &&
    css`
      &::after {
        position: sticky;
        z-index: 1;
        bottom: -1px;
        display: block;
        height: 1px;
        box-shadow: 0 -1px 4px 0 rgb(0 0 0 / 0.75);
        content: '';
      }
    `
  );
}

type StyledScrollableContainerProps = {
  $hasScrollableContentBottom: boolean;
};

const ScrollableContainer = styled(Box)<StyledScrollableContainerProps>`
  overflow-x: hidden;
  overflow-y: auto;

  ${({ $hasScrollableContentBottom }) =>
    hasScrollableContentBottomStyles($hasScrollableContentBottom)}
`;

const AccordionBodyNonScrollable = styled(AccordionBody)`
  overflow: initial !important;
  max-height: none;
`;

export function formatToSidebarItem({
  enabled = true,
  isNew = false,
  isBeta = false,
  icon,
  label,
  to,
  permissionResource,
  position,
  children,
  hasSeparator,
}: MenuItem & { hasSeparator?: FeatureCheck }): SidebarItem {
  return {
    key: label,
    to,
    dataTest: `nav-item-${label}`,
    icon: icon as FunctionComponent,
    title: label,
    position,
    resources: permissionResource as RESOURCE[] | undefined,
    enabled: () => enabled,
    isBeta: () => isBeta,
    isNew: () => isNew,
    children: children?.map((child) => formatToSidebarItem(child)),
    hasSeparator,
  };
}

export function SidebarContent() {
  const featuresFlags = useFeaturesFlags();
  const adminFeatures = useAdminFeatures();
  const features = useMemo(
    () => ({ ...featuresFlags, ...adminFeatures }),
    [adminFeatures, featuresFlags]
  );
  const { t } = useTranslation();
  const { topLevelPath } = useRoutePath();
  const { checkAccess } = usePermissions();
  const { currentCompany } = useDashboardExtensionContext();

  const { menuItems: extensionsMenuItems } = useModuleFederationContext();

  const defaultSelected = SIDEBAR_ITEMS_CONFIG[topLevelPath]
    ? SIDEBAR_ITEMS_CONFIG[topLevelPath].key
    : SIDEBAR_ITEMS_CONFIG[DEFAULT_PAGE].key;
  const { showAnalyticsPlus } = features;

  const { addons } = useCompanyAddons();
  const { getConfig, isChildRouteIncluded } = useGetLinkAddonsConfig();

  const accordionItems = useMemo(() => {
    const extensionsItems = extensionsMenuItems.map<SidebarItem>(formatToSidebarItem);

    const menuItemsEnabled = [...extensionsItems, ...Object.values(SIDEBAR_ITEMS_CONFIG)].filter(
      ({ enabled, hidden }) => enabled(features, currentCompany) && !hidden?.(features)
    );

    const items = sortByPosition(menuItemsEnabled)
      .map(
        ({
          key: itemKey,
          to,
          icon,
          title,
          dataTest,
          children,
          isAd,
          isBeta,
          isNew,
          display,
          resource,
          resources,
        }) => {
          let isLocked = false;

          if (resource) {
            isLocked = !checkAccess(PERMISSIONS_ACTIONS.READ, resource);
          } else if (resources?.length) {
            isLocked = resources.some((r) => !checkAccess(PERMISSIONS_ACTIONS.READ, r));
          }
          const addonConfig = getConfig(to, addons);
          const tag: NavItemProps['tag'] = addonConfig?.tag;

          // if sidebar item has a children create a sub accordion.
          const childItems = children
            ?.map((childData) => {
              if (
                !childData.enabled(features, currentCompany) ||
                (childData.display && !childData.display(features))
              ) {
                return null;
              }

              let isChildLocked = false;

              if (childData?.resource) {
                isChildLocked = !checkAccess(PERMISSIONS_ACTIONS.READ, childData.resource);
              }

              if (childData.key === 'statsAnalyticsPlusAd' && !showAnalyticsPlus) {
                return <AnalyticsPlusAdNavItem {...childData} />;
              }

              return (
                <NavItem
                  key={childData.key}
                  selectedBgColor='neutral-100'
                  hasParent
                  to={childData.to}
                  iconComponent={childData.icon}
                  text={t(childData.title)}
                  data-test={childData.dataTest}
                  isAd={childData.isAd}
                  isBeta={childData.isBeta(features)}
                  isNew={!!childData.isNew?.(features)}
                  isLocked={isLocked || isChildLocked}
                  indicator={
                    isChildRouteIncluded(childData.to, addonConfig)
                      ? addonConfig?.indicator
                      : undefined
                  }
                />
              );
            })
            .filter(isTruthy);

          if (display && !display(features)) {
            return null;
          }

          const accordionHeader = (
            <AccordionHeader>
              <NavItem
                hasChildren={!!childItems?.length}
                to={to}
                iconComponent={icon}
                text={t(title)}
                data-test={dataTest}
                isAd={isAd}
                isBeta={isBeta(features)}
                isNew={!!isNew?.(features)}
                isLocked={isLocked}
                tag={tag}
              />
            </AccordionHeader>
          );

          if (children) {
            return (
              <AccordionItem id={itemKey} key={itemKey}>
                {accordionHeader}
                <AccordionBodyNonScrollable>{childItems}</AccordionBodyNonScrollable>
              </AccordionItem>
            );
          }

          return (
            <AccordionItem id={itemKey} key={itemKey}>
              {accordionHeader}
            </AccordionItem>
          );
        }
      )
      .filter(isTruthy);

    return items;
  }, [
    extensionsMenuItems,
    features,
    currentCompany,
    getConfig,
    addons,
    t,
    checkAccess,
    showAnalyticsPlus,
    isChildRouteIncluded,
  ]);

  const { onScrollHandler, scrollableContainerRef, hasScrollableContentBottom } =
    useHasScrollableContent<HTMLDivElement>();

  return (
    <ScrollableContainer
      ref={scrollableContainerRef}
      onScroll={onScrollHandler}
      $hasScrollableContentBottom={hasScrollableContentBottom}
    >
      <Accordion defaultSelected={defaultSelected}>{accordionItems}</Accordion>
    </ScrollableContainer>
  );
}
