import { useCallback } from 'react';

import { Banner, BannerHeading, BannerIcon, Button, Flex, Form } from '@aircall/tractor-v2';
import { FormField, validateEmail } from '@dashboard/library';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { Form as RFForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export interface SsoLoginFormValues {
  email: string;
}

export function SsoLoginForm() {
  const { t } = useTranslation();

  const {
    actions: { signInSsoToIdp },
    authState: { signedInError },
  } = useAuthenticationState();

  const handleSubmitForm = useCallback(
    (values: SsoLoginFormValues) => {
      signInSsoToIdp(values.email);
    },
    [signInSsoToIdp]
  );

  return (
    <RFForm
      onSubmit={handleSubmitForm}
      render={({ handleSubmit }) => (
        <Flex data-test='sso-login-form' flexDirection='column' gap='s'>
          {signedInError && (
            <Banner variant='critical' data-test='sso-login-form-error' inline>
              <BannerIcon />
              <BannerHeading>{t(signedInError)}</BannerHeading>
            </Banner>
          )}

          <Form onSubmit={handleSubmit}>
            <Flex flexDirection='column' gap='s'>
              <FormField
                name='email'
                label={t('login.form.email.label')}
                placeholder={t('login.form.email.placeholder')}
                validate={validateEmail}
                getErrorMessage={t}
                data-test='sso-signin-email-input'
                size='regular'
              />

              <Button data-test='sso-signin-button' size='regular' block type='submit'>
                {t('login.sso.form.submit')}
              </Button>
            </Flex>
          </Form>
        </Flex>
      )}
    />
  );
}
