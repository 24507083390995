/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';

import { COOKIE_KEYS } from '@constants/auth.constants';
import { AIRCALL_BRIDGE_INSTALL_ENDPOINTS } from '@constants/endpoints.constants';
import { AIRCALL_DASHBOARD_URL } from '@constants/environment.constants';
import { APPLICATIONS_NAMES, APPLICATIONS_TYPES } from '@constants/integrations.constants';
import { INTEGRATION_FULL_ROUTES } from '@constants/routes.constants';
import { routeReplacer } from '@dashboard/library';
import {
  CreateSlackIntegrationMutation,
  CreateSlackIntegrationMutationVariables,
} from '@generated/CreateSlackIntegrationMutation';
import {
  CreateWebhookMutationIA,
  CreateWebhookMutationIAVariables,
} from '@generated/CreateWebhookMutationIA';
import { CreateWebhookInput, RestSlackIntegrationCallbackData } from '@generated/globalTypes';
import { CREATE_SLACK_INTEGRATION_MUTATION } from '@graphql/mutations/CreateSlackIntegrationMutation';
import { CREATE_WEBHOOK_MUTATION_IA } from '@graphql/mutations/CreateWebhookMutation';
import { WEBHOOKS_QUERY_IA } from '@graphql/queries/WebhooksQuery';
import { fieldNameDenormalizerOverride } from '@helpers/apollo.helpers';
import { useGraphMutation } from '@hooks/useMutation';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import { useTracker } from '@hooks/useTracker/useTracker';
import Cookies from 'js-cookie';

export interface UseCreateWebhookIntegrationParams {
  integrationName: string;
}

export interface UseCreateWebhookIntegrationReturnType {
  onSubmit: (input: CreateWebhookInput | RestSlackIntegrationCallbackData) => void;
}

/**
 * Hook to create a webhook integration given a integration name.
 * @param useCreateWebhookIntegrationParams - destructured parameter
 * @returns an object holding the function to create a webhook integration
 */
export function useCreateWebhookIntegration({
  integrationName,
}: UseCreateWebhookIntegrationParams): UseCreateWebhookIntegrationReturnType {
  const navigate = useNavigateWithParamsReplace();

  const { track } = useTracker();

  const [createWebhookIA] = useGraphMutation<
    CreateWebhookMutationIA,
    CreateWebhookMutationIAVariables
  >(CREATE_WEBHOOK_MUTATION_IA, {
    onCompleted: ({ createWebhook: { id } }) => {
      track({
        event: 'integration_installation_funnel_completed',
        payload: { application_name: integrationName },
      });

      navigate(
        `${INTEGRATION_FULL_ROUTES.FLOW_COMPLETE}?integration_id=${id}`,
        {
          applicationName: integrationName.toLowerCase(),
          applicationType: APPLICATIONS_TYPES.WEBHOOK,
          applicationId: '0',
        },
        { replace: true }
      );
    },
    // Needed to avoid a 404 while trying to reach the newly created webhook settings page
    refetchQueries: [{ query: WEBHOOKS_QUERY_IA }],
    awaitRefetchQueries: true,
  });

  const [createRestSlackIntegration] = useGraphMutation<
    CreateSlackIntegrationMutation,
    CreateSlackIntegrationMutationVariables
  >(CREATE_SLACK_INTEGRATION_MUTATION, {
    onCompleted: ({ createRestSlackIntegration: { authorizeUri } }) => {
      track({
        event: 'integration_installation_funnel_completed',
        payload: { application_name: integrationName },
      });

      window.location.assign(authorizeUri);
    },
  });

  const onSubmit = useCallback(
    async (input: CreateWebhookInput | RestSlackIntegrationCallbackData) => {
      const webhook = input as CreateWebhookInput;
      const slack = input as RestSlackIntegrationCallbackData;

      // eslint-disable-next-line default-case
      switch (integrationName.toLowerCase()) {
        case APPLICATIONS_NAMES.WEBHOOK:
          await createWebhookIA({ variables: { input: webhook } });
          break;
        case APPLICATIONS_NAMES.FRONT:
          window.location.assign(
            routeReplacer(AIRCALL_BRIDGE_INSTALL_ENDPOINTS.FRONT_INSTALL_URL, {
              webhookUrl: webhook.url,
              bearerToken:
                /* istanbul ignore next */
                Cookies.get(COOKIE_KEYS.AC_REFRESH_TOKEN)!,
            })
          );
          break;
        case APPLICATIONS_NAMES.SLACK:
          createRestSlackIntegration({
            variables: {
              input: {
                dashboard_url: AIRCALL_DASHBOARD_URL,
                install_params: {
                  callback_name: 'create_webhook',
                  callback_data: slack,
                },
              },
              fieldNameDenormalizer: fieldNameDenormalizerOverride,
            },
          });
      }
    },
    [createRestSlackIntegration, integrationName, createWebhookIA]
  );

  return { onSubmit };
}
