import { gql } from '@apollo/client';

export const GET_APPLICATION_INSTALL_URI_QUERY = gql`
  query GetApplicationInstallUriQuery($applicationID: ID!) {
    getApplicationInstallUri(applicationID: $applicationID) {
      installUri
      source
    }
  }
`;
