import { AuthorizeApplication } from './AuthorizeApplication';

import { DataLayout } from '@components/DataLayout/DataLayout';
import {
  GetAuthorizedApplicationQuery,
  GetAuthorizedApplicationQueryVariables,
} from '@generated/GetAuthorizedApplicationQuery';
import { GET_AUTHORIZED_APPLICATION_QUERY } from '@graphql/queries/GetAuthorizedApplicationQuery';
import { NotFoundError } from '@helpers/errors.helpers';
import { useQueryParams } from '@hooks/useQueryParams';

export function OauthAuthorizePage() {
  const { searchParams } = useQueryParams();
  const clientId = searchParams.get('client_id');
  const redirectUri = searchParams.get('redirect_uri');
  const responseType = searchParams.get('response_type');
  const scope = searchParams.get('scope');

  const state = searchParams.get('state');
  const hideConnectedNumber = searchParams.get('hideConnectedNumber');

  const displayNumbersStep = !JSON.parse(hideConnectedNumber as string);

  if (!clientId || !redirectUri || !responseType || !scope) {
    throw new NotFoundError('Missing required query params', {
      clientId,
      redirectUri,
      responseType,
      scope,
    });
  }

  return (
    <DataLayout<GetAuthorizedApplicationQuery, GetAuthorizedApplicationQueryVariables>
      query={GET_AUTHORIZED_APPLICATION_QUERY}
      queryOptions={{
        variables: {
          clientId,
          redirectUri,
          responseType,
          scope,
          state,
        },
      }}
    >
      {({ data }) => (
        <AuthorizeApplication
          application={data?.getAuthorizedApplication}
          displayNumbersStep={displayNumbersStep}
        />
      )}
    </DataLayout>
  );
}
