import type { SidebarItem } from '@components/Sidebar/sidebarItems';
import { PERMISSIONS_ACTIONS } from '@constants/permissions.constants';
import { TEAMS_ROUTE, USERS_ROUTE, VIRTUAL_AGENTS_ROUTE } from '@constants/routes.constants';
import type { usePermissions } from '@hooks/usePermissions';
import { matchPath } from 'react-router-dom';

export function isRouteRestricted(
  item: Pick<SidebarItem, 'resource' | 'resources'>,
  checkAccess: ReturnType<typeof usePermissions>['checkAccess']
): boolean {
  const resources: SidebarItem['resources'] = (item.resources ?? []).concat(
    item.resource ? [item.resource] : []
  );

  return resources.some((r) => !checkAccess(PERMISSIONS_ACTIONS.READ, r));
}

export function isParentRouteActive(item: SidebarItem, currentPath: string): boolean {
  if (item.children) {
    return item.children.some((child) =>
      Boolean(
        matchPath(
          {
            path: child.to,
            end: false,
          },
          currentPath
        )
      )
    );
  }

  return Boolean(
    matchPath(
      {
        path: item.to?.split('/')?.[1],
        end: false,
      },
      currentPath
    )
  );
}

export function getItemLabelAndPosition(to: string): {
  label?: string;
  position?: number;
  hasSeparator?: () => boolean;
} {
  switch (to) {
    case USERS_ROUTE:
      return {
        label: 'sidenav.all_users_link',
        position: 2,
        hasSeparator: () => true,
      };
    case TEAMS_ROUTE:
      return {
        label: 'sidenav.all_teams_link',
        position: 3,
        hasSeparator: () => true,
      };
    case VIRTUAL_AGENTS_ROUTE:
      return {
        label: 'sidenav.all_virtual_agents_link',
      };
    default:
      return {};
  }
}
