import { useCallback, useLayoutEffect } from 'react';

import { UseContractCheckAndSignReturnType } from './useContractCheckAndSign.decl';

import { logger } from '@config/logger.config';
import { IRONCLAD_CONTRACT_EVENTS } from '@constants/contract.constants';
import { getDaysDifference } from '@dashboard/library';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useIroncladContracts } from '@hooks/useIroncladContracts';
import { useTracker } from '@hooks/useTracker';
import { useNavigate } from 'react-router-dom';

export function useContractCheckAndSign(): UseContractCheckAndSignReturnType {
  const navigate = useNavigate();
  const { showOnboardingTrial, enableStarterPlan, dashboardStarterEnforceTos } = useFeaturesFlags();

  const {
    data: { contractIds, versionIds },
    updateCompanySignerData,
    checkIfCompanyHasSignedLatestContracts,
    acceptContractTerms,
  } = useIroncladContracts();
  const { track } = useTracker();

  const { currentCompany, isTrial, currentUser } = useGlobalData();
  const now = new Date();
  const trialEndDate = new Date(isTrial ? currentCompany.trial!.trialEnd : now);
  const daysLeftInTrial = getDaysDifference(trialEndDate, now);

  const {
    authState: { isConnectedAs },
  } = useAuthenticationState();

  const checkAndSendAcceptance = useCallback(async () => {
    if (!contractIds || !versionIds || isConnectedAs) {
      return;
    }

    const { id: companyId } = currentCompany;
    const { ID: userId } = currentUser;

    try {
      await updateCompanySignerData();
      const hasSignedLatest = await checkIfCompanyHasSignedLatestContracts();
      if (!hasSignedLatest) {
        await acceptContractTerms();

        logger.info('Sent Ironclad acceptance', { userId });
        track({
          event: IRONCLAD_CONTRACT_EVENTS.ACCEPTANCE_SENT,
          payload: {
            userId,
            companyId,
          },
        });
      }
    } catch (error) {
      logger.error(`Error checking or sending Ironclad acceptance: ${(error as Error).message}`);
      track({
        event: IRONCLAD_CONTRACT_EVENTS.ACCEPTANCE_FAILED,
        payload: {
          userId,
          companyId,
          error: (error as Error).message,
        },
      });
    }
  }, [
    contractIds,
    versionIds,
    isConnectedAs,
    currentCompany,
    currentUser,
    updateCompanySignerData,
    checkIfCompanyHasSignedLatestContracts,
    acceptContractTerms,
    track,
  ]);

  useLayoutEffect(() => {
    if ((showOnboardingTrial || enableStarterPlan) && !dashboardStarterEnforceTos) {
      checkAndSendAcceptance();
    }
  }, [
    checkAndSendAcceptance,
    navigate,
    showOnboardingTrial,
    enableStarterPlan,
    dashboardStarterEnforceTos,
    currentUser.ID,
  ]);

  return { daysLeftInTrial };
}
