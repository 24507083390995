export function checkIsUrlFromDomains(url: string, domains: string[]): boolean {
  try {
    const parsedUrl = new URL(url);
    const { hostname } = parsedUrl;

    return hostname.split('.').some((_, i, parts) => {
      const domain = parts.slice(i).join('.');
      return domains.includes(domain);
    });
  } catch {
    // Invalid url
    return false;
  }
}
