import { AnalyticsDashboardProps } from './AnalyticsDashboard.decl';

import { FeedbackButton } from '@components/FeedbackButton';
import { LookerPage } from '@pages/looker-v2/Looker.page';

export function AnalyticsDashboard({
  feedbackButtonEvent,
  maxDateRangeInDays,
  minDate,
  onTruncated,
  pageHeaderTitle,
  title,
  type,
  pageHeaderProps,
}: AnalyticsDashboardProps) {
  return (
    <>
      {feedbackButtonEvent && (
        <FeedbackButton data-test='analytics-feedback-button' event={feedbackButtonEvent} />
      )}
      <LookerPage
        maxDateRangeInDays={maxDateRangeInDays}
        minDate={minDate}
        onTruncated={onTruncated}
        pageHeaderTitle={pageHeaderTitle}
        title={title}
        type={type}
        pageHeaderProps={pageHeaderProps}
      />
    </>
  );
}
