/**
 * Takes an ISO date string `YYYY-MM-DDTHH:MM:SSZ` and converts it to an epoch timestamp
 * in millseconds.
 */
export function convertISOtoEpoch(isoDate: string | null): string {
  if (!isoDate) {
    return '';
  }

  const date = new Date(isoDate);
  const time = date.getTime();

  if (isNaN(time)) {
    return '';
  }

  return time.toString();
}
