import { useCallback } from 'react';

import { PasswordLoginForm, LoginFormValues } from './PasswordLoginForm.container';

import { LockedOutlined } from '@aircall/icons';
import { Flex, Typography, Button, getColor, ButtonWithChildren, Box } from '@aircall/tractor-v2';
import { GoogleLoginButton } from '@components/GoogleLoginButton';
import { SeparatorLabel } from '@components/LoginLayout/Login.styles';
import { ResellerConfig } from '@config/resellers.config';
import { LOGIN_SSO_ROUTE } from '@constants/routes.constants';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace/useNavigateWithParamsReplace';
import styled from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';

export const SsoSigninButton = styled<ButtonWithChildren>(Button)`
  color: ${getColor('neutral-400')};
  font-size: 16px;

  &:hover {
    color: ${getColor('text-base')};
  }
`;

export function PasswordLoginSection() {
  const { t } = useTranslation();
  const {
    actions: { signIn },
  } = useAuthenticationState();
  const navigate = useNavigateWithParamsReplace();

  const {
    featureSet: { shouldShowGoogleSignIn, enableSaml },
  } = ResellerConfig;

  const onSubmit = useCallback(
    (values: LoginFormValues) => {
      signIn(values);
    },
    [signIn]
  );

  const handleSso = useCallback(() => {
    navigate(LOGIN_SSO_ROUTE, undefined, { keepParams: true });
  }, [navigate]);

  const hasLoginOptions = enableSaml || shouldShowGoogleSignIn;

  return (
    <Box data-test='password-login-section'>
      <Box pb='s' mb='s'>
        <Typography variant='headingMediumL' textAlign='center' color='text-base' as='h1' m='0'>
          {t('login.sign_in')}
        </Typography>
      </Box>

      <PasswordLoginForm onSubmit={onSubmit} />

      {hasLoginOptions ? (
        <Box data-test='login-options'>
          <Box py='s'>
            <SeparatorLabel variant='bodyMediumS'>{t('signup.or')}</SeparatorLabel>
          </Box>

          <Flex flexDirection='column' gap='m'>
            {shouldShowGoogleSignIn && <GoogleLoginButton />}

            {enableSaml && (
              <SsoSigninButton
                data-test='sso-login-button'
                mode='link'
                variant='alternative'
                onClick={handleSso}
              >
                <LockedOutlined />
                {t('login.sso.sign_in_sso')}
              </SsoSigninButton>
            )}
          </Flex>
        </Box>
      ) : null}
    </Box>
  );
}
