import { AdminRoutesWrapperProps } from './AdminRoutesWrapper.decl';

import { TermsOfUseConditionalAccess } from '@components/TermsOfUse';
import { useInitGainsight } from '@hooks/useInitGainsight/useInitGainsight';
import { useInitTandemAiScripts } from '@hooks/useInitTandemAi/useInitTandemAi';
import { useInitTracker } from '@hooks/useInitTracker/useInitTracker';
import { useLanguageUpdater } from '@hooks/useLanguageUpdater';
import { useTrackPage } from '@hooks/useTrackPage/useTrackPage';

export function AdminRoutesWrapper({ children: routes }: AdminRoutesWrapperProps) {
  useInitTracker();
  useLanguageUpdater();
  useTrackPage();
  useInitGainsight();
  useInitTandemAiScripts();

  return <TermsOfUseConditionalAccess>{routes}</TermsOfUseConditionalAccess>;
}
