import styled from 'styled-components';

import { Flex, getColor, getSpace, Typography, Grid } from '@aircall/tractor-v2';
import { DESKTOP_DEVICE } from '@constants/responsive.contants';

export const FloatingWrapper = styled(Flex)`
  position: absolute;
  right: ${getSpace('xxl')};
  bottom: ${getSpace('xl')};
`;

export const LoadingContainer = styled(Flex)`
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  inset: 0;
`;

export const LeftWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
`;

export const LayoutWrapper = styled(Grid)`
  && a {
    color: ${getColor('text-interactive-primary')};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: ${DESKTOP_DEVICE}) {
    grid-template-columns: 1fr;

    ${LeftWrapper} {
      display: none;
    }
  }
`;

export const RightWrapper = styled(Flex)`
  position: relative;
`;

export const ButtonWrapper = styled.div`
  margin-top: ${getSpace('l')};

  > button {
    width: 100%;
  }
`;

export const FormWrapper = styled.div`
  width: 80%;
  max-width: 400px;
`;

export const BackgroundImage = styled(Flex)<{ imagePath: string }>`
  height: 100%;
  flex: 1;
  padding: 90px 0 0 100px;
  background-image: ${({ imagePath }) => `url(/assets/${imagePath}.png)`};
  background-position: right;
  background-size: cover;
`;

export const ImageWrapper = styled.div`
  max-width: 512px;

  > svg {
    max-width: 100%;
  }

  > div:first-child svg {
    margin-bottom: ${getSpace('xl')};
    margin-left: ${getSpace('xl')};
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${getColor('divider')};
`;

export const SeparatorLabel = styled(Typography).attrs(({ children }) => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'data-content': typeof children === 'string' ? children : '',
}))`
  position: relative;
  color: ${getColor('text-secondary')};
  padding-block: ${getSpace('xxxs')};
  padding-inline: ${getSpace('xxs')};
  text-align: center;

  &::before {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${getColor('graphic-default')};
    content: '';
    transform: translateY(-50%);
  }

  &::after {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    display: block;
    height: 100%;
    background-color: ${getColor('surface-default')};
    color: transparent;
    content: attr(data-content);
    padding-inline: ${getSpace('xxs')};
    transform: translate(-50%, -50%);
  }
`;
