import { useMemo } from 'react';

import type { AddonItemProps, AddonTitleProps, SidenavAddonsProps } from './SidenavAddons.decl';
import { SidenavDropdown } from './SidenavAddons.styles';
import { addonDataTest, getAddonDurationLabel } from './SidenavAddons.utils';

import {
  Box,
  Flex,
  SidenavItem,
  SidenavItemIndicator,
  SidenavItemTitle,
  Typography,
} from '@aircall/tractor-v2';
import { ReactComponent as AdvancedMessagingIcon } from '@assets/icons/advanced_messaging_icon.svg';
import { ReactComponent as AircallAIIcon } from '@assets/icons/aircall_ai_icon.svg';
import { ReactComponent as AircallSurveysIcon } from '@assets/icons/aircall_surveys_icon.svg';
import { ReactComponent as AnalyticsPlusIcon } from '@assets/icons/sidenav/analytics_plus.svg';
import { ADDONS } from '@constants/addons.constants';
import { useDashboardExtensionContext } from '@dashboard/extension';
import { useNavigateToAddonPage } from '@dashboard/library';
import { useCompanyAddons } from '@hooks/useCompanyAddons/useCompanyAddons';
import {
  getActiveAddonTrials,
  orderAddonTrialsByAscendingRemainingDays,
} from '@hooks/useCompanyAddons/utils';
import { useGetEnableTrialAddonsFeatureFlags } from '@hooks/useGetEnableTrialAddonsFeatureFlags';
import { useTranslation } from 'react-i18next';

function getAddonTrialIcon(name: ADDONS) {
  switch (name) {
    case ADDONS.AIRCALL_AI:
      return <AircallAIIcon width={24} height={24} />;
    case ADDONS.ADVANCED_MESSAGING:
      return <AdvancedMessagingIcon width={24} height={24} />;
    case ADDONS.AIRCALL_SURVEYS:
      return <AircallSurveysIcon width={24} height={24} />;
    case ADDONS.ANALYTICS_PLUS:
    default:
      return <AnalyticsPlusIcon width={24} height={24} />;
  }
}

function AddonTitle({ title, subtitle }: Readonly<AddonTitleProps>) {
  if (!subtitle) {
    return <SidenavItemTitle>{title}</SidenavItemTitle>;
  }

  return (
    <Flex flexDirection='column'>
      <Typography
        variant='bodySemiboldS'
        textOverflow='ellipsis'
        overflow='hidden'
        whiteSpace='nowrap'
      >
        {title}
      </Typography>
      <Typography
        variant='supportingRegularS'
        textOverflow='ellipsis'
        overflow='hidden'
        whiteSpace='nowrap'
      >
        {subtitle}
      </Typography>
    </Flex>
  );
}

function AddonItem({ addon, expanded }: Readonly<AddonItemProps>) {
  const { t } = useTranslation();
  const { track } = useDashboardExtensionContext();
  const navigateToAddonPage = useNavigateToAddonPage(track);

  const { name, remainingDays } = addon;
  const dataTest = addonDataTest(name);
  const addonName = t(`addons.${name}.name`);

  return (
    <SidenavItem
      key={addon.name}
      indicator={<SidenavItemIndicator>{getAddonTrialIcon(name)}</SidenavItemIndicator>}
      tooltip={addonName}
      component='button'
      onClick={() => navigateToAddonPage(name, dataTest)}
      data-test={dataTest}
      title={
        <AddonTitle
          title={t('addons.sidenav.trial_addon', {
            addonName,
          })}
          subtitle={getAddonDurationLabel(remainingDays, t)}
        />
      }
      expanded={expanded}
    />
  );
}

export function SidenavAddons({ expanded }: Readonly<SidenavAddonsProps>) {
  const { t } = useTranslation();
  const { isAddonEnabled } = useGetEnableTrialAddonsFeatureFlags();
  const { addons } = useCompanyAddons();

  const orderedActiveAddonTrials = useMemo(() => {
    if (!addons.length) {
      return [];
    }

    const activeAddonTrials = getActiveAddonTrials(addons, isAddonEnabled);

    return orderAddonTrialsByAscendingRemainingDays(activeAddonTrials);
  }, [addons, isAddonEnabled]);

  const content = useMemo(() => {
    const addonCount = orderedActiveAddonTrials.length;

    if (!addonCount) {
      return null;
    }

    if (addonCount === 1) {
      return <AddonItem addon={orderedActiveAddonTrials[0]} expanded={expanded} />;
    }

    const addonCountLabel = t('addons.sidenav.ongoing_trials', {
      count: addonCount,
    });

    return (
      <SidenavDropdown
        indicator={
          <SidenavItemIndicator>
            {getAddonTrialIcon(orderedActiveAddonTrials[0].name)}
          </SidenavItemIndicator>
        }
        component='button'
        tooltip={addonCountLabel}
        title={<AddonTitle title={addonCountLabel} />}
        expanded={expanded}
      >
        {orderedActiveAddonTrials.map((addon) => (
          <AddonItem key={addon.name} addon={addon} expanded />
        ))}
      </SidenavDropdown>
    );
  }, [orderedActiveAddonTrials, expanded, t]);

  return <Box mt='auto'>{content}</Box>;
}
