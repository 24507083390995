import { SidenavDropdown, getRadii } from '@aircall/tractor-v2';
import styled, { css } from '@xstyled/styled-components';

const scrollbarStyles = css`
  ::-webkit-scrollbar {
    inline-size: 16px;
  }

  ::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
    border-radius: ${getRadii('full')};
    background: #b2b2b2;
    background-clip: padding-box;
  }

  @supports not selector(::-webkit-scrollbar) {
    scrollbar-color: #b2b2b2 transparent;
  }
`;

export const SidenavDropdownContainer = styled(SidenavDropdown)`
  max-block-size: 210px;
  overflow-y: auto;

  ${scrollbarStyles};
`;

export const SidenavItemLi = styled.li`
  display: contents;
`;
