import { useCallback } from 'react';

import { SsoSigninButton } from '../PasswordLoginSection/PasswordLogin.section';

import { SsoLoginForm } from './SsoLoginForm.container';

import { MailOutlined } from '@aircall/icons';
import { Typography, Box, Flex } from '@aircall/tractor-v2';
import { GoogleLoginButton } from '@components/GoogleLoginButton';
import { SeparatorLabel } from '@components/LoginLayout/Login.styles';
import { ResellerConfig } from '@config/resellers.config';
import { LOGIN_ROUTE } from '@constants/routes.constants';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace/useNavigateWithParamsReplace';
import { useTranslation } from 'react-i18next';

export function SsoLoginSection() {
  const { t } = useTranslation();
  const navigate = useNavigateWithParamsReplace();

  const {
    featureSet: { shouldShowGoogleSignIn },
  } = ResellerConfig;

  const navigateToLoginPage = useCallback(() => {
    navigate(LOGIN_ROUTE, undefined, { keepParams: true });
  }, [navigate]);

  return (
    <Box data-test='sso-login-section'>
      <Box pb='s' mb='s'>
        <Typography
          variant='headingMediumL'
          color='text-base'
          as='h1'
          m='0'
          mb='s'
          textAlign='center'
        >
          {t('login.sso.sign_in_sso')}
        </Typography>

        <Typography variant='bodyRegularM' color='text-base' textAlign='center'>
          {t('login.sso.sign_in_sso_description')}
        </Typography>
      </Box>

      <SsoLoginForm />

      <Box py='s'>
        <SeparatorLabel variant='bodyMediumS'>{t('signup.or')}</SeparatorLabel>
      </Box>

      <Flex flexDirection='column' gap='m'>
        {shouldShowGoogleSignIn && <GoogleLoginButton />}

        <SsoSigninButton
          data-test='password-login-button'
          variant='alternative'
          mode='link'
          onClick={navigateToLoginPage}
        >
          <MailOutlined />
          {t('login.sign_in_with_email_password')}
        </SsoSigninButton>
      </Flex>
    </Box>
  );
}
