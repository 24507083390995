/* eslint-disable @typescript-eslint/naming-convention */
import { memo, useState } from 'react';

import { SidenavAccount } from './components/SidenavAccount/SidenavAccount';
import { SidenavAddons } from './components/SidenavAddons/SidenavAddons';
import { SidenavBody } from './components/SidenavBody/SidenavBody';
import { SidenavFooter } from './components/SidenavFooter/SidenavFooter';
import { SidenavHeader } from './components/SidenavHeader/SidenavHeader';
import { SidenavMain } from './components/SidenavMain/SidenavMain';
import { SidenavTrial } from './components/SidenavTrial/SidenavTrial';
import { Container } from './Sidenav.styles';

import { useTracker } from '@hooks/useTracker';

export const Sidenav = memo(() => {
  const { track } = useTracker();
  const [expanded, setExpanded] = useState(true);

  function toggleExpanded() {
    setExpanded((prev) => !prev);
    track({
      event: 'sidebar_menu_toggled',
      payload: { new_display: expanded ? 'collapse' : 'expanded' },
    });
  }

  return (
    <Container $expanded={expanded}>
      <SidenavHeader expanded={expanded} toggleExpanded={toggleExpanded} />
      <SidenavBody>
        <SidenavMain expanded={expanded} />
        <SidenavAddons expanded={expanded} />
        <SidenavTrial expanded={expanded} />
      </SidenavBody>
      <SidenavAccount expanded={expanded} />
      <SidenavFooter expanded={expanded} />
    </Container>
  );
});
